import axios from 'axios';
const STORAGE_KEY = 'accessToken';
const HOST = process.env.REACT_APP_HOST;

// const HOST_DEV = "http://127.0.0.1:8000";
// const HOST_PRO = "https://fser.app";


// Create a new instance of axios with defaults
const myAxios = axios.create({
  baseURL: `${HOST}/api/v1/`,
  headers: {
    // Set default headers for all requests
    'Content-Type': 'application/json',
  },
});

// Add an interceptor to exclude JWT token header for login request
myAxios.interceptors.request.use(
  (config) => {
    if (!config.url.endsWith('/login')) {
      // Add JWT token header for all requests except login
      const token = localStorage.getItem(STORAGE_KEY);
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
        
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
myAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    if(!error.config.url.endsWith('/login/admin') && error.response && error.response.status === 401)
    {
        // Redirect to login page for 403 Forbidden responses
        localStorage.removeItem(STORAGE_KEY)
        window.location.href = '/auth/jwt/login';
    }
    if (error.response && error.response.status === 403) {
      // Redirect to login page for 403 Forbidden responses
      // localStorage.removeItem(STORAGE_KEY)
      // window.location.href = '/auth/jwt/login';
      window.location.href = '/dashboard/wos/all';

    }
    
    return Promise.reject(error);
  }
);

export default myAxios;
