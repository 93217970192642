import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip, SvgIcon } from '@mui/material';
import AlignLeft02Icon from 'src/icons/untitled-ui/duocolor/align-left-02';
import BarChartSquare02Icon from 'src/icons/untitled-ui/duocolor/bar-chart-square-02';
import Building04Icon from 'src/icons/untitled-ui/duocolor/building-04';
import CalendarIcon from 'src/icons/untitled-ui/duocolor/calendar';
import CheckDone01Icon from 'src/icons/untitled-ui/duocolor/check-done-01';
import CreditCard01Icon from 'src/icons/untitled-ui/duocolor/credit-card-01';
import CurrencyBitcoinCircleIcon from 'src/icons/untitled-ui/duocolor/currency-bitcoin-circle';
import File01Icon from 'src/icons/untitled-ui/duocolor/file-01';
import GraduationHat01Icon from 'src/icons/untitled-ui/duocolor/graduation-hat-01';
import HomeSmileIcon from 'src/icons/untitled-ui/duocolor/home-smile';
import LayoutAlt02Icon from 'src/icons/untitled-ui/duocolor/layout-alt-02';
import LineChartUp04Icon from 'src/icons/untitled-ui/duocolor/line-chart-up-04';
import Lock01Icon from 'src/icons/untitled-ui/duocolor/lock-01';
import LogOut01Icon from 'src/icons/untitled-ui/duocolor/log-out-01';
import Mail03Icon from 'src/icons/untitled-ui/duocolor/mail-03';
import Mail04Icon from 'src/icons/untitled-ui/duocolor/mail-04';
import MessageChatSquareIcon from 'src/icons/untitled-ui/duocolor/message-chat-square';
import ReceiptCheckIcon from 'src/icons/untitled-ui/duocolor/receipt-check';
import Share07Icon from 'src/icons/untitled-ui/duocolor/share-07';
import ShoppingBag03Icon from 'src/icons/untitled-ui/duocolor/shopping-bag-03';
import ShoppingCart01Icon from 'src/icons/untitled-ui/duocolor/shopping-cart-01';
import Truck01Icon from 'src/icons/untitled-ui/duocolor/truck-01';
import Upload04Icon from 'src/icons/untitled-ui/duocolor/upload-04';
import Users03Icon from 'src/icons/untitled-ui/duocolor/users-03';
import XSquareIcon from 'src/icons/untitled-ui/duocolor/x-square';
import SettingsIcon from '@mui/icons-material/Settings';
import GradingIcon from '@mui/icons-material/Grading';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import FactoryIcon from '@mui/icons-material/Factory';
import TireRepairIcon from '@mui/icons-material/TireRepair';
import InventoryIcon from '@mui/icons-material/Inventory';
import SecurityIcon from '@mui/icons-material/Security';
import { tokens } from 'src/locales/tokens';
import { paths } from 'src/paths';
import { useAuth } from 'src/hooks/use-auth';
import DescriptionIcon from '@mui/icons-material/Description';
export const useSections = () => {
  const { t } = useTranslation();
  const {user} = useAuth();
  return useMemo(() => {


    function filterRoutesByPermission(routes, user) {
      const filteredRoutes = [];
    
      for (const route of routes) {
        const filteredRoute = { ...route };
    
        if (hasPermission(filteredRoute, user)) {
          if (filteredRoute.items) {
            filteredRoute.items = filterRoutesByPermission(filteredRoute.items, user);
            if (filteredRoute.items.length > 0) {
              filteredRoutes.push(filteredRoute);
            }
          } else {
            filteredRoutes.push(filteredRoute);
          }
        }
      }
    
      return filteredRoutes;
    }
    
    function hasPermission(route, user) {
      if (route.permission) {
        for (const role of user.roles) {
          for (const userPermission of role.permissions) {
            if (userPermission.name === route.permission) {
              return true;
            }
          }
        }
    
        return false;
      }
    
      if (route.items) {
        const filteredItems = filterRoutesByPermission(route.items, user);
        route.items = filteredItems;
        return filteredItems.length > 0;
      }
    
      return false;
    }

    
    const routes =  [
      // {
      //   items: [
      //     {
      //       permission:'dashboard/view',
      //       title: t(tokens.nav.powerbi),
      //       path: paths.dashboard.powerbi.index,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <BarChartSquare02Icon />
      //         </SvgIcon>
      //       )
      //     },
          
      //   ]
      // },
      {
        items: [
          {
            permission:'dashboard/view',
            title: t(tokens.nav.overview),
            path: paths.dashboard.index,
            icon: (
              <SvgIcon fontSize="small">
                <BarChartSquare02Icon />
              </SvgIcon>
            )
          },
          {
            permission:'reports/list',
            title: t(tokens.nav.reports),
            path: paths.dashboard.reports.index,
            icon: (
              <SvgIcon fontSize="small">
                <DescriptionIcon />
              </SvgIcon>
            )
          },
        ],
        
      },
      {
        title: t(tokens.nav.workOrders),
        path: paths.dashboard.wos.ps.index,
        icon: (
          <SvgIcon fontSize="small">
            <GradingIcon />
          </SvgIcon>
        ),
        items: [
          {
            permission:'workorder/list',
            title: t(tokens.nav.allWorkOrders),
            path: paths.dashboard.wos.all.index,
            icon: (
              <SvgIcon fontSize="small">
                <GradingIcon />
              </SvgIcon>
            )
          },
          {
            permission:'workorder/list',
            title: t(tokens.nav.psWorkOrders),
            path: paths.dashboard.wos.ps.index,
            icon: (
              <SvgIcon fontSize="small">
                <GradingIcon />
              </SvgIcon>
            )
          },
          {
            permission:'workorder/list',
            title: t(tokens.nav.rpWorkOrders),
            path: paths.dashboard.wos.rp.index,
            icon: (
              <SvgIcon fontSize="small">
                <GradingIcon />
              </SvgIcon>
            )
          },
          {
            permission:'workorder/list',
            title: t(tokens.nav.rmWorkOrders),
            path: paths.dashboard.wos.rm.index,
            icon: (
              <SvgIcon fontSize="small">
                <GradingIcon />
              </SvgIcon>
            )
          },
         
          {
            permission:'po/list',
            title: t(tokens.nav.po),
            path: paths.dashboard.pos.index,
            icon: (
              <SvgIcon fontSize="small">
                <GradingIcon />
              </SvgIcon>
            )
          },
          {
            permission:'invoice/list',
            title: t(tokens.nav.invoices),
            path: paths.dashboard.invoices.index,
            icon: (
              <SvgIcon fontSize="small">
                <ReceiptIcon />
              </SvgIcon>
            )
          },
        ]
      },
      
      
      {
        subheader: t(tokens.nav.concepts),
        items: [
          {
            permission:'vehicle/list',
            title: t(tokens.nav.vehicles),
            path: paths.dashboard.vehicles.index,
            icon: (
              <SvgIcon fontSize="small">
                <Truck01Icon />
              </SvgIcon>
            ),
            items: [
              {
                permission:'vehicle/list',
                title: t(tokens.nav.list),
                path: paths.dashboard.vehicles.index
              },
              {
                permission:'vehicle/create',
                title: t(tokens.nav.create),
                path: paths.dashboard.vehicles.create
              },
            
            ]
          },
          {
            permission:'man-power/list',
            title: t(tokens.nav.manPowers),
            path: paths.dashboard.manPowers.index,
            icon: (
              <SvgIcon fontSize="small">
                <AccessibilityNewIcon />
              </SvgIcon>
            ),
            items: [
              {
                permission:'man-power/list',
                title: t(tokens.nav.list),
                path: paths.dashboard.manPowers.index
              },
            
              {
                permission:'man-power/create',

                title: t(tokens.nav.create),
                path: paths.dashboard.manPowers.create
              }
            ]
          },
          {
            permission:'vehicle-type/list',
            title: t(tokens.nav.vehicleTypes),
            path: paths.dashboard.vehicleTypes.index,
            icon: (
              <SvgIcon fontSize="small">
                <LocalShippingIcon />
              </SvgIcon>
            ),
            items: [
              {
                permission:'vehicle-type/list',
                title: t(tokens.nav.list),
                path: paths.dashboard.vehicleTypes.index
              },
             
              {
                permission:'vehicle-type/create',
                title: t(tokens.nav.create),
                path: paths.dashboard.vehicleTypes.create
              },
             
            ]
          },
          {
            permission:'manufacturer/list',
            title: t(tokens.nav.manufacturers),
            path: paths.dashboard.manufacturers.index,
            icon: (
              <SvgIcon fontSize="small">
                <FactoryIcon />
              </SvgIcon>
            ),
            items: [
              {
                permission:'manufacturer/list',
                title: t(tokens.nav.list),
                path: paths.dashboard.manufacturers.index
              },
              {
                permission:'manufacturer/create',
                title: t(tokens.nav.create),
                path: paths.dashboard.manufacturers.create
              },
             
            ]
          },
          {
            permission:'tire/list',
            title: t(tokens.nav.tires),
            path: paths.dashboard.tires.index,
            icon: (
              <SvgIcon fontSize="small">
                <TireRepairIcon />
              </SvgIcon>
            ),
            items: [
              {
                permission:'tire/list',
                title: t(tokens.nav.list),
                path: paths.dashboard.tires.index
              },
             
            ]
          },
          {
            permission:'item/list',
            title: t(tokens.nav.inventory),
            path: paths.dashboard.items.index,
            icon: (
              <SvgIcon fontSize="small">
                <InventoryIcon />
              </SvgIcon>
            ),
            items: [
              {
                permission:'item/list',
                title: t(tokens.nav.listItems),
                path: paths.dashboard.inventory.items.index,
              },
              {
                permission:'item/create',
                title: t(tokens.nav.createItem),
                path: paths.dashboard.inventory.items.create
              },
              
              {
                permission:'patch/list',
                title: t(tokens.nav.listPatches),
                path: paths.dashboard.inventory.patches.index,
              },
              {
                permission:'patch/upload',
                title: t(tokens.nav.createPatch),
                path: paths.dashboard.inventory.patches.create,
              },
            ]
          },
          {
            permission:'maintenance/list',
            title: t(tokens.nav.maintenances),
            path: paths.dashboard.maintenances.index,
            icon: (
              <SvgIcon fontSize="small">
                <CalendarIcon />
              </SvgIcon>
            ),
            items: [
              {
                permission:'maintenance/list',
                title: t(tokens.nav.list),
                path: paths.dashboard.maintenances.index
              },
             
            ]
          },
          
         
        ]
      },
      { // for settings
        subheader: t(tokens.nav.settings),
        items: [
          {
            permission:'setting/list',
            title: t(tokens.nav.settings),
            path: paths.dashboard.settings.index,
            icon: (
              <SvgIcon fontSize="small">
                <SettingsIcon />
              </SvgIcon>
            )
          },
          
        ]
      },
      {
        subheader: t(tokens.nav.security),
        items: [
          {
            permission:'user/list',
            title: t(tokens.nav.users),
            path: paths.dashboard.users.index,
            icon: (
              <SvgIcon fontSize="small">
                <Users03Icon />
              </SvgIcon>
            ),
            items: [
              {
                permission:'user/list',
                title: t(tokens.nav.list),
                path: paths.dashboard.users.index
              },
             
              {
                permission:'user/create',
                title: t(tokens.nav.create),
                path: paths.dashboard.users.create
              }
            ]
          },
          {
            permission:'roles/list',
            title: t(tokens.nav.roles),
            path: paths.dashboard.roles.index,
            icon: (
              <SvgIcon fontSize="small">
                <SecurityIcon />
              </SvgIcon>
            ),
            items: [
              {
                permission:'roles/list',
                title: t(tokens.nav.list),
                path: paths.dashboard.roles.index
              },
              
              {
                permission:'roles/create',
                title: t(tokens.nav.create),
                path: paths.dashboard.roles.create
              }
            ]
          }
        ]
      },
      
    ];

    const filteredRoutes = filterRoutesByPermission(routes, user)

    return filteredRoutes;

  }, [t]);
};
