import { createContext, useCallback, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { authApi } from 'src/api/auth';
import { Issuer } from 'src/utils/auth';

const STORAGE_KEY = 'accessToken';
const STORAGE_KEY_USER_DATA = 'userData';

var ActionType;
(function (ActionType) {
  ActionType['INITIALIZE'] = 'INITIALIZE';
  ActionType['SIGN_IN'] = 'SIGN_IN';
  // ActionType['SIGN_UP'] = 'SIGN_UP';
  ActionType['SIGN_OUT'] = 'SIGN_OUT';
})(ActionType || (ActionType = {}));

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  twoFARequired:false,
  username:null,
  password:null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      twoFARequired:false,
      username:null,
      password:null,
    };
  },
  SIGN_IN: (state, action) => {
    const { user,isTWOFARequired ,username, password} = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      twoFARequired:isTWOFARequired,
      username,
      password,
    };
  },
  SIGN_UP: (state, action) => {
    const { user,isTWOFARequired,username, password}  = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      twoFARequired:isTWOFARequired,
      username, 
      password
    };
  },
  SIGN_OUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    twoFARequired:false,
    username:null,
    password:null
  }), 
  
};

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state);

export const AuthContext = createContext({
  ...initialState,
  issuer: Issuer.JWT,
  signIn: () => Promise.resolve(),
  // signUp: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
  twoFA: ()=> Promise.resolve()
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);// reducer is function resposible for updating the state based on action dispatched, initialized is initial value of object

  const initialize = useCallback(async () => {
    try {
      const accessToken = window.localStorage.getItem(STORAGE_KEY);
      const userData = JSON.parse(window.localStorage.getItem(STORAGE_KEY_USER_DATA));

      if (accessToken) {
        //const user = await authApi.me({ accessToken });
        // console.log('User Data fromn context: ',userData)
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: true,
            user:userData
          }
        });
      } else {
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: ActionType.INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null
        }
      });
    }
  }, [dispatch]);

  useEffect(() => {
      initialize();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  const signIn = useCallback(async (username, password) => {
    const { accessToken, userData } = await authApi.signIn({ username, password });
    // const user = await authApi.me({ accessToken , userData});
    // console.log('Sign in response user data : ',userData)
    if(userData && userData['2fa'] === 'auth_app')
    {
      // console.log('2fa required, credentials:' ,username,password)
      dispatch({
        type: ActionType.SIGN_IN,
        payload: {
          user:userData,
          isTWOFARequired:true,
          username,
          password
        }
      });
      return true; // true means 2fa enabled
    }
    else{
      localStorage.setItem(STORAGE_KEY, accessToken);
      localStorage.setItem(STORAGE_KEY_USER_DATA, JSON.stringify(userData));
  
      dispatch({
        type: ActionType.SIGN_IN,
        payload: {
          user:userData,
          isTWOFARequired:false,
          username:null, 
          password:null
        }
      });
      return false;// no 2fa
    }
    
  }, [dispatch]);

  const twoFA = useCallback(async (code, username, password) => {
    // console.log('sent credentials are: ' +code +'  '+ username + ' ' + password)
    const { accessToken, userData } = await authApi.verify({ code,username,password });
    
    localStorage.setItem(STORAGE_KEY, accessToken);
    localStorage.setItem(STORAGE_KEY_USER_DATA, JSON.stringify(userData));

    dispatch({
      type: ActionType.SIGN_IN,
      payload: {
        user:userData,
        isTWOFARequired:false,
        username:null,
        password:null
      }
    });
  }, [dispatch]);

  // const signUp = useCallback(async (email, name, password) => {
  //   const { accessToken } = await authApi.signUp({ email, name, password });
  //   const user = await authApi.me({ accessToken });

  //   localStorage.setItem(STORAGE_KEY, accessToken);

  //   dispatch({
  //     type: ActionType.SIGN_UP,
  //     payload: {
  //       user,
  //     }
  //   });
  // }, [dispatch]);

  const signOut = useCallback(async () => {
    localStorage.removeItem(STORAGE_KEY);
    localStorage.removeItem(STORAGE_KEY_USER_DATA);

    dispatch({ type: ActionType.SIGN_OUT });
  }, [dispatch]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        issuer: Issuer.JWT,
        signIn,
        // signUp,
        signOut,
        twoFA
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const AuthConsumer = AuthContext.Consumer;
