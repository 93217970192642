export const paths = {
  index: '/',
  checkout: '/checkout',
  contact: '/contact',
  pricing: '/pricing',
  auth: {
    auth0: {
      callback: '/auth/auth0/callback',
      login: '/auth/auth0/login'
    },
    jwt: {
      login: '/auth/jwt/login',
      register: '/auth/jwt/register',
       verify: '/auth/jwt/verify'

    },
    firebase: {
      login: '/auth/firebase/login',
      register: '/auth/firebase/register'
    },
    amplify: {
      confirmRegister: '/auth/amplify/confirm-register',
      forgotPassword: '/auth/amplify/forgot-password',
      login: '/auth/amplify/login',
      register: '/auth/amplify/register',
      resetPassword: '/auth/amplify/reset-password'
    }
  },
  authDemo: {
    forgotPassword: {
      classic: '/auth-demo/forgot-password/classic',
      modern: '/auth-demo/forgot-password/modern'
    },
    login: {
      classic: '/auth-demo/login/classic',
      modern: '/auth-demo/login/modern'
    },
    register: {
      classic: '/auth-demo/register/classic',
      modern: '/auth-demo/register/modern'
    },
    resetPassword: {
      classic: '/auth-demo/reset-password/classic',
      modern: '/auth-demo/reset-password/modern'
    },
    verifyCode: {
      classic: '/auth-demo/verify-code/classic',
      modern: '/auth-demo/verify-code/modern'
    }
  },
 
  dashboard: {
    index: '/dashboard',
    start: {
      index: '/dashboard/start',
    },
    academy: {
      index: '/dashboard/academy',
      courseDetails: '/dashboard/academy/courses/:courseId'
    },
    reports: {
      index: '/dashboard/reports',
      // courseDetails: '/dashboard/academy/courses/:courseId'
    },
    powerbi: {
      index: '/dashboard/powerbi',
    },
    account: '/dashboard/account',
    analytics: '/dashboard/analytics',
    blank: '/dashboard/blank',
    blog: {
      index: '/dashboard/blog',
      postDetails: '/dashboard/blog/:postId',
      postCreate: '/dashboard/blog/create'
    },
    calendar: '/dashboard/calendar',
    chat: '/dashboard/chat',
    crypto: '/dashboard/crypto',
    customers: {
      index: '/dashboard/customers',
      details: '/dashboard/customers/:customerId',
      edit: '/dashboard/customers/:customerId/edit'
    },
    manPowers: {
      index: '/dashboard/man-powers',
      details: '/dashboard/man-powers/:manPowerId',
      edit: '/dashboard/man-powers/:manPowerId/edit',
      create: '/dashboard/man-powers/create'
    },
    
    vehicles: {
      index: '/dashboard/vehicles',
      details: '/dashboard/vehicles/:vehicleId',
      edit: '/dashboard/vehicles/:vehicleId/edit',
      create: '/dashboard/vehicles/create'
    },
    vehicleTypes: {
      index: '/dashboard/vehicle-types',
      details: '/dashboard/vehicle-types/:vehicleTypeId',
      edit: '/dashboard/vehicle-types/:vehicleTypeId/edit',
      create: '/dashboard/vehicle-types/create'
    },
    manufacturers: {
      index: '/dashboard/manufacturers',
      details: '/dashboard/manufacturers/:manufacturerId',
      edit: '/dashboard/manufacturers/:manufacturerId/edit',
      create: '/dashboard/manufacturers/create'
    },
    inventory: {
      patches:{
        index: '/dashboard/inventory/patches',
        details: '/dashboard/inventory/patches/:patchId',
        edit: '/dashboard/inventory/patches/:patchId/edit',
        create: '/dashboard/inventory/patches/create'
      },
      items:{
        index: '/dashboard/inventory/items',
        details: '/dashboard/inventory/items/:itemId',
        edit: '/dashboard/inventory/items/:itemId/edit',
        create: '/dashboard/inventory/items/create',
        append:'/dashboard/inventory/items/:itemId/append'
      }
      
    },
    tires: {
      index: '/dashboard/tires',
      details: '/dashboard/tires/:tireId',
      edit: '/dashboard/tires/:tireId/edit',
      create: '/dashboard/tires/create'
    },
    items: {
      index: '/dashboard/items',
      details: '/dashboard/items/:itemId',
      edit: '/dashboard/items/:itemId/edit',
      create: '/dashboard/items/create',
      upload: '/dashboard/items/upload',
    },
    maintenances: {
      index: '/dashboard/maintenances',
      details: '/dashboard/maintenances/:maintenanceId',
      edit: '/dashboard/maintenances/:maintenanceId/edit',
      create: '/dashboard/maintenances/create',
    },
    users: {
      index: '/dashboard/users',
      details: '/dashboard/users/:userId',
      edit: '/dashboard/users/:userId/edit',
      create: '/dashboard/users/create'

    },
    roles: {
      index: '/dashboard/roles',
      details: '/dashboard/roles/:roleId',
      edit: '/dashboard/roles/:roleId/edit',
      create: '/dashboard/roles/create'
    },
    settings: {
      index: '/dashboard/settings',
      details: '/dashboard/settings/:settingId',
      edit: '/dashboard/settings/:settingId/edit',
      create: '/dashboard/settings/create'
    },
    wos: {
      ps:{
        index: '/dashboard/wos/ps',
        details: '/dashboard/wos/ps/:workOrderId',
        edit: '/dashboard/wos/ps/:workOrderId/edit',
        create: '/dashboard/wos/ps/create'
      },
      rp:{
        index: '/dashboard/wos/rp',
        details: '/dashboard/wos/rp/:workOrderId',
        edit: '/dashboard/wos/rp/:workOrderId/edit',
        create: '/dashboard/wos/rp/create'
      },
      all:{
        index: '/dashboard/wos/all',
        // details: '/dashboard/wos/rp/:workOrderId',
        // edit: '/dashboard/wos/rp/:workOrderId/edit',
        // create: '/dashboard/wos/rp/create'
      },
      rm:{
        index: '/dashboard/wos/rm',
        details: '/dashboard/wos/rm/:workOrderId',
        edit: '/dashboard/wos/rm/:workOrderId/edit',
        create: '/dashboard/wos/rm/create'
      }
    },
    pos: {
      index: '/dashboard/pos',
      details: '/dashboard/pos/:poId',
      edit: '/dashboard/pos/:poId/edit',
      create: '/dashboard/pos/create'
    },
    invoices: {
      index: '/dashboard/invoices',
      details: '/dashboard/invoices/:invoiceId',
      edit: '/dashboard/invoices/:invoiceId/edit',
      // create: '/dashboard/workOrders/create'
    },
    ecommerce: '/dashboard/ecommerce',
    fileManager: '/dashboard/file-manager',
    
    jobs: {
      index: '/dashboard/jobs',
      create: '/dashboard/jobs/create',
      companies: {
        details: '/dashboard/jobs/companies/:companyId'
      }
    },
    kanban: '/dashboard/kanban',
    logistics: {
      index: '/dashboard/logistics',
      fleet: '/dashboard/logistics/fleet'
    },
    mail: '/dashboard/mail',
    orders: {
      index: '/dashboard/orders',
      details: '/dashboard/orders/:orderId'
    },
    products: {
      index: '/dashboard/products',
      create: '/dashboard/products/create'
    },
    social: {
      index: '/dashboard/social',
      profile: '/dashboard/social/profile',
      feed: '/dashboard/social/feed'
    }
  },
  components: {
    index: '/components',
    dataDisplay: {
      detailLists: '/components/data-display/detail-lists',
      tables: '/components/data-display/tables',
      quickStats: '/components/data-display/quick-stats'
    },
    lists: {
      groupedLists: '/components/lists/grouped-lists',
      gridLists: '/components/lists/grid-lists'
    },
    forms: '/components/forms',
    modals: '/components/modals',
    charts: '/components/charts',
    buttons: '/components/buttons',
    typography: '/components/typography',
    colors: '/components/colors',
    inputs: '/components/inputs'
  },
  docs: 'https://material-kit-pro-react-docs.devias.io',
  401: '/401',
  404: '/404',
  500: '/500'
};
