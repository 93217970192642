export const tokens = {
  common: {
    languageChanged: 'common.languageChanged'
  },
  nav: {
    academy: 'nav.academy',
    account: 'nav.account',
    analytics: 'nav.analytics',
    auth: 'nav.auth',
    blog: 'nav.blog',
    browse: 'nav.browse',
    calendar: 'nav.calendar',
    chat: 'nav.chat',
    checkout: 'nav.checkout',
    concepts: 'nav.concepts',
    contact: 'nav.contact',
    course: 'nav.course',
    create: 'nav.create',
    crypto: 'nav.crypto',
    customers: 'nav.customers',
    dashboard: 'nav.dashboard',
    details: 'nav.details',
    ecommerce: 'nav.ecommerce',
    edit: 'nav.edit',
    error: 'nav.error',
    feed: 'nav.feed',
    fileManager: 'nav.fileManager',
    files: 'nav.files',
    finance: 'nav.finance',
    fleet: 'nav.fleet',
    forgotPassword: 'nav.forgotPassword',
    invoiceList: 'nav.invoices',
    jobList: 'nav.jobList',
    kanban: 'nav.kanban',
    list: 'nav.list',
    login: 'nav.login',
    logistics: 'nav.logistics',
    mail: 'nav.mail',
    management: 'nav.management',
    orderList: 'nav.orders',
    overview: 'nav.overview',
    pages: 'nav.pages',
    postCreate: 'nav.postCreate',
    postDetails: 'nav.postDetails',
    postList: 'nav.postList',
    pricing: 'nav.pricing',
    productList: 'nav.products',
    profile: 'nav.profile',
    register: 'nav.register',
    resetPassword: 'nav.resetPassword',
    socialMedia: 'nav.socialMedia',
    verifyCode: 'nav.verifyCode',

    manPowers: 'nav.manPowers',
    vehicles: 'nav.vehicles',
    vehicleTypes: 'nav.vehicleTypes',
    manufacturers: 'nav.manufacturers',
    tires: 'nav.tires',
    inventory: 'nav.inventory',
    maintenances: 'nav.maintenances',
    
    security: 'nav.security',
    users: 'nav.users',
    roles: 'nav.roles',
    settings: 'nav.settings',
    powerbi: 'nav.powerbi',
    upload: 'nav.upload',
    listItems: 'nav.listItems',
    createItem: 'nav.createItem',
    listPatches: 'nav.listPatches',
    createPatch: 'nav.createPatch',
    
    workOrders: 'nav.workOrders',
    allWorkOrders: 'nav.allWorkOrders',
    psWorkOrders: 'nav.psworkOrders',
    rpWorkOrders: 'nav.rpworkOrders',
    rmWorkOrders: 'nav.rmworkOrders',
    po: 'nav.po',
    invoices:'nav.invoices',
    reports:'nav.reports'

  }
};
