import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Layout as DashboardLayout } from 'src/layouts/dashboard';

const IndexPage = lazy(() => import('src/pages/dashboard/index'));
// const StartPage = lazy(() => import('src/pages/dashboard/start'));

// Academy
// const AcademyDashboardPage = lazy(() => import('src/pages/dashboard/academy/dashboard'));
// const AcademyCoursePage = lazy(() => import('src/pages/dashboard/academy/course'));

// Reports
const ReportPage = lazy(() => import('src/pages/dashboard/reports/dashboard'));


// PO
const POListPage = lazy(() => import('src/pages/dashboard/po/list'));
const PODetailPage = lazy(() => import('src/pages/dashboard/po/details'));
const POEditPage = lazy(() => import('src/pages/dashboard/po/edit'));
const POCreatePage = lazy(() => import('src/pages/dashboard/po/create'));

// Work Orders / PS
const WorkOrderPSListPage = lazy(() => import('src/pages/dashboard/work-orders/ps/list'));
const WorkOrderPSDetailPage = lazy(() => import('src/pages/dashboard/work-orders/ps/details'));
const WorkOrderPSEditPage = lazy(() => import('src/pages/dashboard/work-orders/ps/edit'));
const WorkOrderPSCreatePage = lazy(() => import('src/pages/dashboard/work-orders/ps/create'));

// Work Orders All
const WorkOrderAllListPage = lazy(() => import('src/pages/dashboard/work-orders/all/list'));


// Work Orders / RP
const WorkOrderRPListPage = lazy(() => import('src/pages/dashboard/work-orders/rp/list'));
const WorkOrderRPDetailPage = lazy(() => import('src/pages/dashboard/work-orders/rp/details'));
const WorkOrderRPEditPage = lazy(() => import('src/pages/dashboard/work-orders/rp/edit'));
const WorkOrderRPCreatePage = lazy(() => import('src/pages/dashboard/work-orders/rp/create'));

// Work Orders / RM
const WorkOrderRMListPage = lazy(() => import('src/pages/dashboard/work-orders/rm/list'));
const WorkOrderRMDetailPage = lazy(() => import('src/pages/dashboard/work-orders/rm/details'));
const WorkOrderRMEditPage = lazy(() => import('src/pages/dashboard/work-orders/rm/edit'));
const WorkOrderRMCreatePage = lazy(() => import('src/pages/dashboard/work-orders/rm/create'));

// Invoices
const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoices/list'));
const InvoiceDetailPage = lazy(() => import('src/pages/dashboard/invoices/detail'));
// const InvoiceEditPage = lazy(() => import('src/pages/dashboard/invoices/edit'));

// Blog
// const BlogPostListPage = lazy(() => import('src/pages/dashboard/blog/list'));
// const BlogPostDetailPage = lazy(() => import('src/pages/dashboard/blog/detail'));
// const BlogPostCreatePage = lazy(() => import('src/pages/dashboard/blog/create'));
// power bi
const PowerBIDashboard = lazy(() => import('src/pages/dashboard/powerbi/dashboard'));

// Customers
// const CustomerListPage = lazy(() => import('src/pages/dashboard/customers/list'));
// const CustomerDetailPage = lazy(() => import('src/pages/dashboard/customers/detail'));
// const CustomerEditPage = lazy(() => import('src/pages/dashboard/customers/edit'));


// Users
const UserListPage = lazy(() => import('src/pages/dashboard/users/list'));
const UserDetailPage = lazy(() => import('src/pages/dashboard/users/detail'));
const UserEditPage = lazy(() => import('src/pages/dashboard/users/edit'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/users/create'));

// Man Power1
const ManPowerListPage = lazy(() => import('src/pages/dashboard/man-power/list'));
const ManPowerDetailPage = lazy(() => import('src/pages/dashboard/man-power/detail'));
const ManPowerEditPage = lazy(() => import('src/pages/dashboard/man-power/edit'));
const ManPowerCreatePage = lazy(() => import('src/pages/dashboard/man-power/create'));

// Settings
const SettingsListPage = lazy(() => import('src/pages/dashboard/settings/list'));
const SettingDetailPage = lazy(() => import('src/pages/dashboard/settings/detail'));
const SettingEditPage = lazy(() => import('src/pages/dashboard/settings/edit'));

// Vehicle Types
const VehicleTypesListPage = lazy(() => import('src/pages/dashboard/vehicle-types/list'));
const VehicleTypeDetailPage = lazy(() => import('src/pages/dashboard/vehicle-types/detail'));
const VehicleTypeEditPage = lazy(() => import('src/pages/dashboard/vehicle-types/edit'));
const VehicleTypeCreatePage = lazy(() => import('src/pages/dashboard/vehicle-types/create'));
// users
// const UserListPage = lazy(() => import('src/pages/dashboard/users/list'));

// Manufacturers
const ManufacturersListPage = lazy(() => import('src/pages/dashboard/manufacturers/list'));
const ManufacturerDetailPage = lazy(() => import('src/pages/dashboard/manufacturers/detail'));
const ManufacturerEditPage = lazy(() => import('src/pages/dashboard/manufacturers/edit'));
const ManufacturerCreatePage = lazy(() => import('src/pages/dashboard/manufacturers/create'));

// // Inventory & Patch
const PatchesListPage = lazy(() => import('src/pages/dashboard/inventory/patches/list'));
const PatchCreatePage = lazy(() => import('src/pages/dashboard/inventory/patches/create'));
const PatchDetailPage = lazy(() => import('src/pages/dashboard/inventory/patches/detail'));

const ItemsListPage = lazy(() => import('src/pages/dashboard/inventory/items/list'));
const ItemsCreatePage = lazy(() => import('src/pages/dashboard/inventory/items/create'));
const ItemDetailPage = lazy(() => import('src/pages/dashboard/inventory/items/detail'));
const ItemEditPage = lazy(() => import('src/pages/dashboard/inventory/items/edit'));
const ItemAppendPage = lazy(() => import('src/pages/dashboard/inventory/items/append'));


// Vehicles
const VehiclesListPage = lazy(() => import('src/pages/dashboard/vehicles/list'));
const VehiclesCreatePage = lazy(() => import('src/pages/dashboard/vehicles/create'));
const VehiclesDetailPage = lazy(() => import('src/pages/dashboard/vehicles/detail'));
const VehiclesEditPage = lazy(() => import('src/pages/dashboard/vehicles/edit'));

// // Invoice
// const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoices/list'));
// const InvoiceDetailPage = lazy(() => import('src/pages/dashboard/invoices/detail'));
// Tires
const TireListPage = lazy(() => import('src/pages/dashboard/tires/list'));
const TireCreatePage = lazy(() => import('src/pages/dashboard/tires/create'));
const TireDetailPage = lazy(() => import('src/pages/dashboard/tires/detail'));
const TireEditPage = lazy(() => import('src/pages/dashboard/tires/edit'));

// Roles
const RoleListPage = lazy(() => import('src/pages/dashboard/roles/list'));
const RoleDetailPage = lazy(() => import('src/pages/dashboard/roles/detail'));
const RoleEditPage = lazy(() => import('src/pages/dashboard/roles/edit'));
const RoleCreatePage = lazy(() => import('src/pages/dashboard/roles/create'));

const MaintenancePage = lazy(() => import('src/pages/dashboard/maintenances/list'));

// Jobs
// const JobBrowsePage = lazy(() => import('src/pages/dashboard/jobs/browse'));
// const JobCreatePage = lazy(() => import('src/pages/dashboard/jobs/create'));
// const CompanyDetailPage = lazy(() => import('src/pages/dashboard/jobs/companies/detail'));

// Logistics
// const LogisticsDashboardPage = lazy(() => import('src/pages/dashboard/logistics/dashboard'));
// const LogisticsFleetPage = lazy(() => import('src/pages/dashboard/logistics/fleet'));

// Orders
// const OrderListPage = lazy(() => import('src/pages/dashboard/orders/list'));
// const OrderDetailPage = lazy(() => import('src/pages/dashboard/orders/detail'));

// Products
// const ProductListPage = lazy(() => import('src/pages/dashboard/products/list'));
// const ProductCreatePage = lazy(() => import('src/pages/dashboard/products/create'));

// Social
// const SocialFeedPage = lazy(() => import('src/pages/dashboard/social/feed'));
// const SocialProfilePage = lazy(() => import('src/pages/dashboard/social/profile'));

// Other
const AccountPage = lazy(() => import('src/pages/dashboard/account'));
// const AnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
// const BlankPage = lazy(() => import('src/pages/dashboard/blank'));

// const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));

// const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
// const CryptoPage = lazy(() => import('src/pages/dashboard/crypto'));
// const EcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
// const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
// const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
// const MailPage = lazy(() => import('src/pages/dashboard/mail'));

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <IndexPage />
      },
     
      
      {
        path: 'powerbi',
        children: [
          {
            index: true,
            element: <PowerBIDashboard />
          }
          
        ]
      },
      
      {
        path: 'reports',
        children: [
          {
            index: true,
            element: <ReportPage />
          }
          
        ]
      },
      {
        path: 'pos',
        children: [
          {
            index: true,
            element: <POListPage />
          },
          {
            path: 'create',
            element: <POCreatePage />
          },
          {
            path: ':poId',
            element: <PODetailPage />
          },
          {
            path: ':poId/edit',
            element: <POEditPage />
          },

        ]
      },
      {
        path: 'invoices',
        children: [
          {
            index: true,
            element: <InvoiceListPage />
          },
         
          {
            path: ':invoiceId',
            element: <InvoiceDetailPage />
          },
          
        ]
      },
      {
        path: 'wos/ps',
        children: [
          {
            index: true,
            element: <WorkOrderPSListPage />
          },
          {
            path: 'create',
            element: <WorkOrderPSCreatePage />
          },
          {
            path: ':workOrderId',
            element: <WorkOrderPSDetailPage />
          },
          {
            path: ':workOrderId/edit',
            element: <WorkOrderPSEditPage />
          },

        ]
      },
      {
        path: 'wos/rp',
        children: [
          {
            index: true,
            element: <WorkOrderRPListPage />
          },
          {
            path: 'create',
            element: <WorkOrderRPCreatePage />
          },
          {
            path: ':workOrderId',
            element: <WorkOrderRPDetailPage />
          },
          {
            path: ':workOrderId/edit',
            element: <WorkOrderRPEditPage />
          },

        ]
      },
      {
        path: 'wos/all',
        children: [
          {
            index: true,
            element: <WorkOrderAllListPage />
          }
          

        ]
      },
      {
        path: 'wos/rm',
        children: [
          {
            index: true,
            element: <WorkOrderRMListPage />
          },
          {
            path: 'create',
            element: <WorkOrderRMCreatePage />
          },
          {
            path: ':workOrderId',
            element: <WorkOrderRMDetailPage />
          },
          {
            path: ':workOrderId/edit',
            element: <WorkOrderRMEditPage />
          },

        ]
      },
      
      {
        path: 'users',
        children: [
          {
            index: true,
            element: <UserListPage />
          },
          {
            path: ':userId',
            element: <UserDetailPage />
          },
          {
            path: ':userId/edit',
            element: <UserEditPage />
          },
          {
            path: 'create',
            element: <UserCreatePage />
          },
        ]
      },
      {
        path: 'man-powers',
        children: [
          {
            index: true,
            element: <ManPowerListPage />
          },
          {
            path: ':manPowerId',
            element: <ManPowerDetailPage />
          },
          {
            path: ':manPowerId/edit',
            element: <ManPowerEditPage />
          },
          {
            path: 'create',
            element: <ManPowerCreatePage />
          }
        ]
      },
      {
        path: 'vehicle-types',
        children: [
          {
            index: true,
            element: <VehicleTypesListPage />
          },
          {
            path: ':vehicleTypeId',
            element: <VehicleTypeDetailPage />
          },
          {
            path: ':vehicleTypeId/edit',
            element: <VehicleTypeEditPage />
          },
          {
            path: 'create',
            element: <VehicleTypeCreatePage />
          }
        ]
      },
      {
        path: 'manufacturers',
        children: [
          {
            index: true,
            element: <ManufacturersListPage />
          },
          {
            path: ':manufacturerId',
            element: <ManufacturerDetailPage />
          },
          {
            path: ':manufacturerId/edit',
            element: <ManufacturerEditPage />
          },
          {
            path: 'create',
            element: <ManufacturerCreatePage />
          }
        ]
      },
      {
        path: 'inventory/items',
        children: [
          {
            index: true,
            element: <ItemsListPage />
          },
          {
            path: 'create',
            element: <ItemsCreatePage />
          },
          {
            path: ':itemId',
            element: <ItemDetailPage />
          },
          {
            path: ':itemId/edit',
            element: <ItemEditPage />
          },
          {
            path: ':itemId/append',
            element: <ItemAppendPage />
          },

        ]
      },
      {
        path:'inventory/patches',
        children:[
          {
            index: true,
            element: <PatchesListPage />
          },
          {
            path: 'create',
            element: <PatchCreatePage />
          },
          {
            path: ':patchId',
            element: <PatchDetailPage />
          },
        ]
      },
      {
        path:'vehicles',
        children:[
          {
            index: true,
            element: <VehiclesListPage />
          },
          {
            path: 'create',
            element: <VehiclesCreatePage />
          },
          {
            path: ':vehicleId',
            element: <VehiclesDetailPage />
          },
          {
            path: ':vehicleId/edit',
            element: <VehiclesEditPage />
          },
        ]
      },
      {
        path: 'settings',
        children: [
          {
            index: true,
            element: <SettingsListPage />
          },
          {
            path: ':settingId',
            element: <SettingDetailPage />
          },
          {
            path: ':settingId/edit',
            element: <SettingEditPage />
          },
         
        ]
      },
      {
        path: 'roles',
        children: [
          {
            index: true,
            element: <RoleListPage />
          },
          {
            path: ':roleId',
            element: <RoleDetailPage />
          },
          {
            path: ':roleId/edit',
            element: <RoleEditPage />
          },
          {
            path: 'create',
            element: <RoleCreatePage />
          }
        ]
      },
      {
        path: 'tires',
        children: [
          {
            index: true,
            element: <TireListPage />
          },
          {
            path: ':tireId',
            element: <TireDetailPage />
          },
          {
            path: ':tireId/edit',
            element: <TireEditPage />
          },
          {
            path: 'create',
            element: <TireCreatePage />
          }
        ]
        },
        {
          path: 'maintenances',
          children: [
            {
              index: true,
              element: <MaintenancePage />
            },
            
          ]
          },
      
      {
        path: 'invoices',
        children: [
          {
            index: true,
            element: <InvoiceListPage />
          },
          {
            path: ':invoiceId',
            element: <InvoiceDetailPage />
          }
        ]
      },
      
      
      
      {
        path: 'account',
        element: <AccountPage />
      },
     
    
    ]
  }
];
