import { Outlet } from 'react-router-dom';
import Error401Page from 'src/pages/401';
import Error404Page from 'src/pages/404';
import Error500Page from 'src/pages/500';
import { authRoutes } from './auth';
import { dashboardRoutes } from './dashboard';
import { Layout as DashboardLayout } from 'src/layouts/dashboard';
import { lazy, Suspense } from 'react';
const IndexPage = lazy(() => import('src/pages/dashboard/index'));
export const routes = [
  {
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: (<IndexPage/>)
      },
     
      
     
    ]
  },
  
  ...authRoutes,
  ...dashboardRoutes,

  {
    path: '401',
    element: <Error401Page />
  },
  {
    path: '404',
    element: <Error404Page />
  },
  {
    path: '500',
    element: <Error500Page />
  },
  {
    path: '*',
    element: <Error404Page />
  }
];
